<template>
  <b-row>
    <b-col sm="12">
      <TableDataWidget
        ref="tableUserInfo"
        title="รูป รอการตรวจสอบ"
        url="/api/notification-data/index"
        @clickNew="$refs.indexFormModal.show()"
        @clickEdit="$refs.indexFormModal.show({ ...$event })"
        @clickDelete="$refs.tableUserInfo.clickDelete('/api/notification-data/index', $event)"
        :columns="columns"
        viewAble
        btnText="เพิ่มข้อความ"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'MutewallIndex',
  components: {
    // indexFormModal: () => import('./components/index/IndexFormModal.vue'),
  },
  data() {
    return {
      columns: [
        {
          label: 'ชื่อ',
          field: 'title',
        },
        {
          label: 'จำนวณรูป',
          field: 'body',
        },
        {
          label: 'จำนวนครั้งที่ส่งตรวจสอบ',
          field: 'created_at',
          type: 'string',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },

  mounted() {},

  methods: {
    clickView(data) {
      // console.log(data)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
